<template>
  <div id="post-index">
    <div class="app-top">
      <!-- <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list> -->
    </div>
    <div class="container">
      <div v-if="dataList.length==0">列表为空</div>
      <div>
        <div v-for="(item,index) in dataList" :key="index">
          <p @click="goDetail(item.code)" style="color:#1997f9;cursor: pointer;">{{item.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/post'

export default {
  name: 'PostIndex',
  components: {},
  data() {
    return {
      dataList: []
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    goDetail(code) {
      this.$router.push({ name: 'PostDetail', params: { code: code } })
    },
    getList() {
      API.getList({ type: 'Other', keyword: '', pageIndex: 1, pageSize: 10 }).then(res => {
        this.dataList = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// 电脑端
.app-top {
  margin-top: 58px;
}
#post-index {
  .container {
    padding: 20px;
  }
}
</style>