import request from '@/common/request/request'

function getList(params) {
  return request({
    url: '/Post/GetList',
    method: 'get',
    params
  })
}
function getCates(params) {
  return request({
    url: '/PostCate/GetList',
    method: 'get',
    params
  })
}

function getOne(params) {
  return request({
    url: '/Post/GetOne',
    method: 'get',
    params
  })
}

export default {
  getList,
  getCates,
  getOne
}
